function boom() {
	const toggle = document.querySelector('.complex-menu-toggle');
	if (!toggle) return null;

	const body = document.querySelector('body');
	const menu = document.querySelector('.complex-menu-wrapper');

	toggle.addEventListener('click', () => {
		toggle.classList.toggle('active');
		menu.classList.toggle('active');
		prepareMobileMenu();
		body.classList.toggle('stop-scroll');
		document
			.querySelector('li.menu-item-has-children.menu-open')
			?.classList.remove('menu-open');
	});
}

/*
 * Prepare mobile menu HTML
 */
function prepareMobileMenu() {
	const isAlreadyDone = document.querySelectorAll('.custom-phone-wrapper');

	if (isAlreadyDone.length !== 0 || window.innerWidth > 777) return;

	const complexNavItems = document.querySelectorAll(
		'.complex-nav > li.menu-item-has-children'
	);

	if (!complexNavItems) return null;

	complexNavItems.forEach((item) => {
		const container = item.querySelector('.sub-menu-wrapper');
		const menusToClone = item.querySelectorAll('.sub-menu.depth-1 li');

		if (menusToClone.length === 0) return;

		//Get title
		const titleContext = container.parentNode.querySelector(
			'a.dropdown-toggle .hidden-mobile-text'
		);

		const mobileMenu = document.createElement('div');
		mobileMenu.classList.add('custom-phone-wrapper');

		if (titleContext) {
			const title = document.createElement('p'); // You can use other heading tags like h1, h2, etc., or even a div or span
			title.classList.add('custom-title');
			title.textContent = titleContext.innerText; // Set the text content of the title
			mobileMenu.appendChild(title); // Append the title to the mobileMenu div
		}

		for (let i = 0; i < menusToClone.length; i++) {
			const menu = menusToClone[i];
			if (i < 2) {
				// Only append the first two menus
				const clone = menu.cloneNode(true);
				clone.classList.add('hello-ul');
				mobileMenu.appendChild(clone);
			}
			// Remove the original menu regardless
			menu.remove();
		}

		container.appendChild(mobileMenu);
		initializeLazyLoading();
	});
}

function mobileMenu() {
	// Get the current window width
	const windowWidth = window.innerWidth;

	const toggleMenus = document.querySelectorAll(
		'.complex-nav > li.menu-item-has-children > a, .complex-nav > li.menu-item-has-children > .menu-arrow'
	);

	toggleMenus.forEach((item) => {
		item.addEventListener('click', (e) => {
			e.preventDefault();

			// Check if the clicked item is already open
			const isActive = item.parentNode.classList.contains('menu-open');

			// If another menu is opened and window width is greater than or equal to 777px, close it

			if (windowWidth >= 777) {
				const openedMenu = document.querySelector(
					'li.menu-item-has-children.menu-open'
				);

				if (openedMenu) {
					openedMenu.classList.remove('menu-open');
				}
			}

			// If the clicked item was not active, open it
			if (!isActive) {
				item.parentNode.classList.add('menu-open');
			} else {
				item.parentNode.classList.remove('menu-open');
			}
		});
	});
}

document.addEventListener('DOMContentLoaded', () => {
	boom();
	mobileMenu();
	scrolledOnPage();
});

function scrolledOnPage() {
	var top = window.pageYOffset || document.documentElement.scrollTop;
	const header = document.querySelector('header');

	//Delowar
	const menuWrapper = document
		.querySelector('.complex-nav')
		?.getBoundingClientRect()?.width;

	const rect = header.getBoundingClientRect();
	const count = rect.height + rect.top;

	header.style.setProperty('--offset-static', count + 'px');
	header.style.setProperty('--primary-menu-width', menuWrapper + 'px');
	if (rect.y + rect.height > top) {
		header.classList.remove('scrolled');
	} else {
		header.classList.add('scrolled');
	}
}

let debounceTimeout;

function handleScroll() {
	if (debounceTimeout) {
		window.cancelAnimationFrame(debounceTimeout);
	}

	debounceTimeout = window.requestAnimationFrame(scrolledOnPage);
}

window.addEventListener('load', scrolledOnPage);
window.addEventListener('scroll', handleScroll);
